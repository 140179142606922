import { v4 as uuidv4 } from "uuid";

import Asterix from "@spectrum-icons/workflow/Asterisk";
import Heart from "@spectrum-icons/workflow/Heart";
import Events from "@spectrum-icons/workflow/Events";
import PauseCircle from "@spectrum-icons/workflow/PauseCircle";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import EmailLightning from "@spectrum-icons/workflow/Email";
import Bug from "@spectrum-icons/workflow/Bug";

import { PUBLIC_ROUTES } from "./publicroutes";
import { PRIVATE_ROUTES } from "./privateroutes";

const FAQ_TRAMWAY = [
  {
    id: uuidv4(),
    title: "À propos de la plateforme et des membres",
    children: [
      {
        id: uuidv4(),
        title: "Tramway, pour qui ?",
        text: "Notre initiative existe pour les célibataires prêts à prendre sérieusement soin de leur cœur et ceux qui cherchent l'amitié ou l’amour, ceux qui empruntent les chemins de traverse que la vie nous offre.",
      },
      {
        id: PUBLIC_ROUTES.manifesto,
        title: "Pourquoi le nom Tramway ?",
        text: "Le nom Tramway évoque un voyage partagé, un cheminement collectif. Il incarne une alternative engagée, écologique et souvent abordable. Tramway symbolise la confiance et les liens profonds au sein de notre communauté.",
        link: PUBLIC_ROUTES.manifesto,
        icon: <Heart />,
      },
      {
        id: uuidv4(),
        title: "Quelle est votre vision à long terme ?",
        text: "Nous aspirons à un monde qui encourage la confiance, l'aventure et les relations interculturelles.",
      },

      {
        id: uuidv4(),
        title: "Existe-t-il une application mobile ?",
        text: "Oui, Tramway est une application progressive installable depuis votre smartphone via le navigateur Chrome. Elle est également accessible sur tablette et ordinateur.",
      },
      {
        id: PUBLIC_ROUTES.accessibilite,
        title: "L’accessibilité numérique ?",
        text: "Tramway s'engage dans l'accessibilité numérique. Cela garantit la participation pleine et entière des personnes en situation de handicap à la société.",
        link: PUBLIC_ROUTES.accessibilite,
        icon: <Asterix />,
      },

      {
        id: uuidv4(),
        title: "Puis-je tester Tramway gratuitement ?",
        text: "Oui, la visite gratuite vous permet de découvrir le site et d'évaluer s'il répond à vos attentes. Vous pouvez créer et compléter votre profil, faire des recherches parmi les profils disponibles, et plus encore.",
        icon: <Events />,
      },
      {
        id: uuidv4(),
        title: "Pourquoi n'y a-t-il pas de forum ?",
        text: "Tramway privilégie les conversations en tête-à-tête et les interactions directes entre ses membres.",
      },
      {
        id: PUBLIC_ROUTES.CGV_CGU,
        title: "Pourquoi mon compte a-t-il été suspendu ?",
        text: "Consultez nos règles et conditions générales pour plus de détails.",
        link: PUBLIC_ROUTES.CGV_CGU,
        icon: <PauseCircle />,
      },
      {
        id: PRIVATE_ROUTES.compte,
        title: "Comment puis-je clôturer mon compte ?",
        text: "Vous pouvez supprimer votre compte via la page dédiée. Votre profil sera définitivement supprimé de nos systèmes.",
        link: PRIVATE_ROUTES.compte,
        icon: <CloseCircle />,
      },

      {
        id: uuidv4(),
        title: "Comment puis-je signaler un membre qui enfreint les règles ?",
        text: "Vous pouvez signaler un membre depuis son profil ou nous contacter directement pour faire part de votre rapport.",
      },
      {
        id: PUBLIC_ROUTES.contact,
        title: "Comment puis-je vous contacter ?",
        text: "Vous pouvez nous contacter via divers canaux : messagerie interne, formulaire de contact, Twitter, ou par e-mail lorsque disponible.",
        link: PUBLIC_ROUTES.contact,
        icon: <EmailLightning />,
      },
    ],
  },
  {
    id: uuidv4(),
    title: "À propos des Cercles",
    children: [
      {
        id: uuidv4(),
        title: "Qu'est-ce qu'un cercle ?",
        text: "Les cercles tramway sont un moyen pour vous de trouver immédiatement les personnes avec lesquelles vous vous connecterez facilement. Nous nous efforcerons d'ajouter au site des moyens de remplir votre vie d'aventures ! Et les cercles sont un pas dans cette direction.",
      },
      {
        id: uuidv4(),
        title: "Je ne trouve pas de cercle qui me convienne",
        text: "Envoyez-nous vos idées de cercles ! Vous pourrez bientôt créer vos propres cercles.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "À propos des bugs et des fonctionnalités",
    children: [
      {
        id: uuidv4(),
        title: "Comment puis-je signaler un Bug ?",
        text: "Utilisez notre formulaire de contact en sélectionnant l'option Bug. Nous vous répondrons rapidement.",
        link: PUBLIC_ROUTES.contact,
        icon: <Bug />,
      },
      {
        id: uuidv4(),
        title: "Où puis-je suggérer une amélioration ou une nouvelle fonctionnalité ?",
        text: "Tramway est actuellement en maintenance. Nous ne prenons pas de demandes de fonctionnalités pour le moment, mais nous vous tiendrons informés de tout changement.",
      },
    ],
  },
];

export { FAQ_TRAMWAY };
